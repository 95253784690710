import request from "@/core/services/axios";
// 查询各种异常记录的数量
export function errortypeNums(params) {
  return request({
    url: `/homework/hwrecord/errortype/nums`,
    method: "get",
    params: params,
  });
}
// 缺页异常-获取异常列表
export function misspageList(params) {
  return request({
    url: `/homework/hwrecord/misspage/list`,
    method: "get",
    params: params,
  });
}
// 获取指定异常列表全部数据（缺页异常、主、客观题异常不适用此接口）
export function getErrorlist(params) {
  return request({
    url: `/homework/hwrecord/get/errorlist`,
    method: "get",
    params: params,
  });
}

//定位异常-重新识别
export function identifyAgain(params) {
  return request({
    url: `/homework/hwrecord/identifyAgain`,
    method: "post",
    data: params,
  });
}
//图像不完整-清空回收站
export function pictureDelete(params) {
  return request({
    url: `/homework/hwrecord/picture/delete`,
    method: "post",
    data: params,
  });
}
//定位异常-旋转-交换
export function hwrecordUpdateurls(params) {
  return request({
    url: `/homework/hwrecord/updateurls`,
    method: "put",
    data: params,
  });
}
//批量设置图像不完整的答题卡为完整
export function setnormal(params) {
  return request({
    url: `/homework/hwrecord/picture/setnormal`,
    method: "post",
    data: params,
  });
}
//图像不完整-批量删除（放入回收站）
export function intorecycle(params) {
  return request({
    url: `/homework/hwrecord/picture/intorecycle`,
    method: "post",
    data: params,
  });
}
//图像不完整-回收站还原
export function reduction(params) {
  return request({
    url: `/homework/hwrecord/picture/reduction`,
    method: "post",
    data: params,
  });
}
// 图像不完整-查询回收站列表
export function getrecyclebin(params) {
  return request({
    url: `/homework/hwrecord/picture/getrecyclebin`,
    method: "get",
    params: params,
  });
}
// 二维码异常-设为正常
export function qrSetnormal(params) {
  return request({
    url: `/homework/hwrecord/qr/setnormal`,
    method: "post",
    data: params,
  });
}
// 获取作业模版
export function hwsheet(params) {
  return request({
    url: `/homework/hwrecord/get/hwsheet`,
    method: "get",
    params: params,
  });
}
// 获取作业模版
export function getstudentlist(params) {
  return request({
    url: `/homework/hwrecord/getstudentlist`,
    method: "get",
    params: params,
  });
}
// 学号填涂异常-指定给学生
export function scribbleStudent(params) {
  return request({
    url: `/homework/hwrecord/scribble/student`,
    method: "get",
    params: params,
  });
}
// 学号重复-指定给学生
export function repeatStudent(params) {
  return request({
    url: `/homework/hwrecord/repeat/student`,
    method: "get",
    params: params,
  });
}
// 客观题异常-获取异常列表
export function objabnormalList(params) {
  return request({
    url: `/homework/hwrecord/objabnormal/list`,
    method: "get",
    params: params,
  });
}
// 客观题异常-获取小题分组数据
export function packetdata(params) {
  return request({
    url: `/homework/hwrecord/objabnormal/packetdata`,
    method: "get",
    params: params,
  });
}
// 客观题异常-获取小题分组数据
export function appointErrortypeNums(params) {
  return request({
    url: `/homework/hwrecord/appoint/errortype/nums`,
    method: "get",
    params: params,
  });
}
// 主观题异常-获取小题分组数据
export function subabnormalList(params) {
  return request({
    url: `/homework/hwrecord/subabnormal/list`,
    method: "get",
    params: params,
  });
}
// 主观题异常-获取小题分组数据
export function subabnormalPacketdata(params) {
  return request({
    url: `/homework/hwrecord/subabnormal/packetdata`,
    method: "get",
    params: params,
  });
}
// 客观题异常-提交处理结果
export function objabnormalSubmit(params) {
  return request({
    url: `/homework/hwrecord/objabnormal/submit`,
    method: "post",
    data: params,
  });
}
// 主观题异常-提交处理结果
export function subabnormalSubmit(params) {
  return request({
    url: `/homework/hwrecord/subabnormal/submit`,
    method: "post",
    data: params,
  });
}
// 缺页异常-核准缺页
export function approval(params) {
  return request({
    url: `/homework/hwrecord/misspage/approval`,
    method: "get",
    params: params,
  });
}
// 重置扫描记录
export function reset(params) {
  return request({
    url: `/homework/hwrecord/reset`,
    method: "post",
    params: params,
  });
}
// 退卡
export function hwrecordBack(data) {
  return request({
    url: `/homework/hwrecord/hwrecord/back`,
    method: "post",
    data: data,
  });
}
// 退回定位异常
export function backLocation(data) {
  return request({
    url: `/homework/hwrecord/hwrecord/back/location`,
    method: "post",
    data: data,
  });
}
// 定位点异常  重新识别
export function locationScan(params) {
  return request({
    url: `/homework/hwrecord/identifyAgain/location`,
    method: "post",
    data: params,
  });
}
