<template>
  <div class="objectiveException handle-cnt">
    <div class="handle-left">
      <div class="err-tips">
        <div>
          异常原因：客观题作答无法识别
          <el-button type="text" @click="$parent.showTips()">提示</el-button>
        </div>
        <div>
          选项个数:
          <el-input-number
            v-model="optionNumber"
            :min="1"
            :max="26"
            label="描述文字"
            style="width: 120px"
            @change="initOption"
          ></el-input-number>
        </div>
      </div>
      <div
        v-if="studentErrList.length > 0 || listLoading"
        v-loading="listLoading"
        class="edit-scroll-style objective-item-box"
      >
        <div
          v-for="(item, index) in studentErrList"
          :key="index"
          class="objective-item"
        >
          <div class="objective-head">
            <div>
              <span style="margin-right: 12px"
                >学号:{{ item.studentHwId }}</span
              >
              <span>姓名:{{ item.studentName }}</span>
            </div>

            <div>
              <el-button type="text" @click="backCark(item)">退卡</el-button>
              <el-button type="text" @click="showPaper(item)"
                >查看原卷</el-button
              >
              <el-button type="text" @click="showPaper(item, 1)">
                查看标注卷
              </el-button>
            </div>
          </div>
          <div class="img-box">
            <img :src="item.scoreUrl" alt="" />
            <!-- <img :src="item.url" alt="" /> -->
          </div>
          <div class="option-box">
            <div
              v-for="(oitem, oindex) in optionArr"
              :key="oindex"
              :class="{ 'btn-on': item.answerText.indexOf(oitem) != -1 }"
              @click="checkOpItem(item, oitem)"
            >
              {{ oitem }}
            </div>
            <div
              :class="{ 'btn-on': item.answerText.length == 0 }"
              @click="item.answerText = []"
            >
              未填
            </div>
          </div>
        </div>
        <div class="sub-btn-box">
          <el-button type="primary" :loading="btnLoading" @click="submit()"
            >提交</el-button
          >
        </div>
      </div>
      <div v-else class="succ-box">
        <div>
          <img src="@/static/scanSheet/default-diagram.png" alt="" />
          <p>异常处理完成，老师您辛苦了!</p>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination
          :current-page="current"
          :page-sizes="[50, 100, 200, 300, 400]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <div class="exceptionList">
      <div class="exception-tilte">{{ schoolInfo.name }}</div>
      <div class="type-check">
        <div
          v-for="(item, index) in typeList"
          :key="index"
          class="type-check-item"
          :class="{ 'type-check-on': isHandle == item.value }"
          @click="checkTypeList(item)"
        >
          {{ item.label }}({{ item.number }})
        </div>
      </div>
      <div class="err-list-box edit-scroll-style">
        <div
          v-for="(item, index) in errorList"
          :key="index"
          class="err-item-box"
          :class="{
            'err-item-on': item.questionGroupId == indexError.questionGroupId,
          }"
          @click="checkItem(item)"
        >
          <div>
            <i class="el-icon-caret-left"></i> {{ item.questionGroupName }}
          </div>
          <span class="error-number">{{ item.number || 0 }}</span>
        </div>
      </div>
    </div>
    <showImgList ref="showImgList" :src-list="imgUrl"></showImgList>
  </div>
</template>
<script>
import { getStore } from "@/core/util/store";
const typeList = [
  { label: "全部", value: 2, number: 0 },
  { label: "待处理", value: 0, number: 0 },
  { label: "已处理", value: 1, number: 0 },
];
const optionStr = "ABCDEFGHIJKLMNOPQRSTVWXYZ";
import {
  objabnormalList,
  packetdata,
  appointErrortypeNums,
  objabnormalSubmit,
  hwrecordBack,
} from "@/core/api/homework/scan";
import showImgList from "@/components/showImgList";
export default {
  name: "ObjectiveException",
  components: {
    showImgList,
  },
  data() {
    return {
      current: 1,
      size: 100,
      total: 0,
      optionStr: optionStr,
      optionArr: [],
      typeList: typeList,
      errorList: [],
      schoolInfo: {},
      indexError: {},
      optionNumber: 4,
      isHandle: 0,
      studentErrList: [],
      imgUrl: [],
      btnLoading: false,
      listLoading: true,
    };
  },
  created() {
    this.getInfo();
    this.initOption();
  },
  methods: {
    handleSizeChange(val) {
      this.size = val;
      this.getObjabnormalList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getObjabnormalList();
    },
    initOption() {
      let arr = optionStr.split("");
      arr.length = this.optionNumber;
      this.optionArr = arr;
    },
    //获取顶部异常数量
    getAappointErrortypeNums() {
      let data = {
        hwId: this.$route.query.hwId,
        errTypeId: this.$route.query.errTypeId,
        type: "",
      };
      appointErrortypeNums(data).then((res) => {
        // console.log(res);
        let data = res.data.data || [];
        this.typeList = this.typeList.map((item) => {
          data.map((index) => {
            if (
              item.value == index.isHandle &&
              index.errorTypeId == this.$route.query.errTypeId
            ) {
              item.number = index.number || 0;
            }
            if (item.value == 2 && index.errorTypeId == 100) {
              item.number = index.number || 0;
            }
          });
          return item;
        });
      });
    },
    // 获取异常列表
    async getPacketdata() {
      let data = {
        hwId: this.$route.query.hwId,
        isHandle: this.isHandle,
      };
      setTimeout(() => {
        this.$parent.$refs.headerBox.init();
      }, 200);
      this.getAappointErrortypeNums();
      await packetdata(data)
        .then((res) => {
          this.errorList = res.data.data || [];
          if (this.errorList.length == 0) {
            this.listLoading = false;
          }
          if (this.indexError.questionGroupId) {
            let arr = this.errorList.filter(
              (item) => item.questionGroupId == this.indexError.questionGroupId
            );
            if (arr && arr.length > 0) {
              this.indexError = arr[0];
            } else {
              this.indexError = this.errorList[0] || {};
            }
          } else {
            this.indexError = this.errorList[0] || {};
          }
        })
        .catch(() => {
          if (this.errorList.length == 0) {
            this.listLoading = false;
          }
        });
    },
    // 获取当时试题异常列表
    getObjabnormalList() {
      this.studentErrList = [];
      if (!this.indexError.questionGroupId) return;
      let data = {
        hwId: this.$route.query.hwId,
        isHandle: this.isHandle,
        questionGroupId: this.indexError.questionGroupId,
        current: this.current,
        size: this.size,
      };
      this.listLoading = true;
      objabnormalList(data)
        .then((res) => {
          if (res.data.data.records && res.data.data.records.length > 0) {
            this.studentErrList = res.data.data.records.map((item) => {
              item.answerText =
                item.exceptionList[0] && item.exceptionList[0].answerText
                  ? item.exceptionList[0].answerText.split("")
                  : [];
              item.id = item.exceptionList[0] ? item.exceptionList[0].id : "";
              return item;
            });
          } else {
            this.studentErrList = [];
          }
          // console.log(this.studentErrList);
          this.total = res.data.data.total || 0;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    checkOpItem(item, oItem) {
      if (item.answerText.indexOf(oItem) != -1) {
        item.answerText = item.answerText.filter((index) => index != oItem);
      } else {
        item.answerText.push(oItem);
      }
    },
    submit() {
      let data = this.studentErrList.map((item) => {
        let arr = Array.from(new Set(item.answerText));
        arr = arr.filter((i) => this.optionArr.indexOf(i) != -1);

        return {
          answerText: arr.sort().join(""),
          hwId: item.hwId,
          id: item.id,
        };
      });
      // console.log(data);

      this.btnLoading = true;
      objabnormalSubmit(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "保存成功！",
            type: "success",
          });
          this.btnLoading = false;
          this.init();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    async init() {
      this.listLoading = true;
      await this.getPacketdata();
      this.getObjabnormalList();
    },
    checkTypeList(item) {
      this.current = 1;
      this.isHandle = item.value;
      this.init();
    },
    // 查看原卷
    showPaper(val, type) {
      if (type) {
        if (!val.markUrls) {
          this.$message({
            message: "当前学生试卷信息为空",
            type: "warning",
            showClose: true,
          });
          return;
        }
        this.imgUrl = val.markUrls.split("，");
        this.$refs.showImgList.showImg = true;
      } else {
        if (!val.urls) {
          this.$message({
            message: "当前学生试卷信息为空",
            type: "warning",
            showClose: true,
          });
          return;
        }
        this.imgUrl = val.urls.split("，");
        this.$refs.showImgList.showImg = true;
      }
    },

    backCark(item) {
      this.$confirm(
        `正在对 ${item.studentName} 学生退卡,退卡后将回到考号填涂异常!`,
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              try {
                await hwrecordBack([item.hwRecordId]);

                setTimeout(() => {
                  this.$message({
                    showClose: true,
                    message: "退卡成功!",
                    type: "success",
                  });
                  instance.confirmButtonLoading = false;
                  done();
                  this.studentErrList = [];
                  this.$parent.$refs.headerBox.init();
                }, 500);
              } catch {
                instance.confirmButtonLoading = false;
              }
            } else {
              done();
            }
          },
        }
      )
        .then(() => {})
        .catch(() => {});
    },

    getNewList() {},
    checkItem(item) {
      this.indexError = item;
      this.current = 1;
      this.getObjabnormalList();
    },
    getInfo() {
      this.schoolInfo = getStore({ name: "schoolInfo" });
    },
  },
};
</script>
<style lang="scss" scoped>
.objectiveException.handle-cnt {
  .err-tips {
    height: 46px;
    padding: 7px 24px;
  }
  .succ-box {
    width: 100%;
    height: calc(100vh - 172px);
  }
  .err-item-box {
    display: flex;
    justify-content: space-between;
  }
  .pagination-box {
    padding: 8px 24px;
  }
  .el-pagination {
    margin-top: 0;
  }
  .objective-item-box {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-row-gap: 24px;
    // grid-column-gap: 24px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 24px 0 24px 24px;
    background: #f7f7f7;
    height: calc(100vh - 183px);
    width: calc(100vw - 441px);
    .option-box {
      display: flex;
      flex-wrap: wrap;
      div {
        width: 46px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #d5d6db;
        text-align: center;
        line-height: 32px;
        margin-right: 8px;
        margin-bottom: 18px;
        cursor: pointer;
        &:hover {
          background: #2474ed;
          color: #ffffff;
          border-color: #2474ed;
        }
      }
      .btn-on {
        background: #2474ed;
        color: #ffffff;
        border-color: #2474ed;
      }
    }
    .objective-item {
      background: #ffffff;
      padding: 24px 24px 0;
      margin-right: 24px;
      margin-bottom: 24px;
      width: calc((100vw - 540px) / 3);
      .img-box {
        max-height: 118px;
        width: 100%;
        margin: 18px 0;
        overflow: hidden;
        text-align: center;
        img {
          max-width: 100%;
        }
      }
      .objective-head {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }
  .err-list-box {
    padding-bottom: 40px;
  }
}
</style>
