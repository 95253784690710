<template>
  <div class="handleException">
    <headerBox ref="headerBox" :sheet-data="sheetData"></headerBox>
    <div
      v-if="$route.query.errTypeId != 14 && $route.query.errTypeId != 16"
      class="handle-cnt"
    >
      <div class="handle-left">
        <div class="err-tips">
          <div>
            <template v-if="$route.query.errTypeId == 2">
              异常原因：图像可能存在残缺或折角
            </template>
            <template v-if="$route.query.errTypeId == 3">
              异常原因：定位信息印刷残缺或扫错答卷
            </template>
            <template v-if="$route.query.errTypeId == 19">
              异常原因：作业二维码无法识别或与当前作业信息不符合
            </template>
            <template v-if="$route.query.errTypeId == 20">
              异常原因：学生答卷页数少于作业页数
            </template>
            <template v-if="$route.query.errTypeId == 6">
              异常原因：学生学号信息无法识别或不在本班
            </template>
            <template v-if="$route.query.errTypeId == 7">
              异常原因：学号错误或重复扫描
            </template>

            <el-button type="text" @click="showTips()">提示</el-button>
          </div>
          <template v-if="$route.query.errTypeId == 20">
            <div class="time">
              共{{ sheetData.numberCount }}张,{{
                sheetData.pageCount * sheetData.numberCount
              }}面试卷
            </div>
          </template>
          <template v-else>
            <div v-if="$refs.pictureProcessing" class="time">
              {{ $refs.pictureProcessing.indexErr.createTime }}
            </div>
          </template>
        </div>
        <pictureProcessing
          ref="pictureProcessing"
          :sheet-data="sheetData"
        ></pictureProcessing>
      </div>
      <exceptionList
        ref="exceptionList"
        :sheet-data="sheetData"
      ></exceptionList>
    </div>
    <objectiveException
      v-if="$route.query.errTypeId == 14"
      ref="objectiveException"
    ></objectiveException>
    <subjectiveException
      v-if="$route.query.errTypeId == 16"
      ref="subjectiveException"
      :sheet-data="sheetData"
    ></subjectiveException>
    <exceptionComment ref="exceptionComment"></exceptionComment>
  </div>
</template>
<script>
import headerBox from "./components/header.vue";
import exceptionList from "./components/exceptionList.vue";
import pictureProcessing from "./components/pictureProcessing.vue";
import objectiveException from "./components/objectiveException.vue";
import subjectiveException from "./components/subjectiveException.vue";
import exceptionComment from "./components/exceptionComment.vue";
import { hwsheet } from "@/core/api/homework/scan";
export default {
  name: "HandleException",
  components: {
    headerBox,
    exceptionList,
    pictureProcessing,
    objectiveException,
    subjectiveException,
    exceptionComment,
  },
  data() {
    return {
      sheetData: {},
    };
  },
  created() {},
  async mounted() {
    await this.gethwsheet();
    this.init();
  },
  methods: {
    async gethwsheet() {
      let data = {
        hwId: this.$route.query.hwId,
      };
      await hwsheet(data).then((res) => {
        this.sheetData = res.data.data;
      });
    },
    init() {
      if (
        this.$route.query.errTypeId != 14 &&
        this.$route.query.errTypeId != 16
      ) {
        this.$nextTick(() => {
          this.$refs.exceptionList.isHandle = 0;
          this.$refs.exceptionList.init();
        });
      }
      if (this.$route.query.errTypeId == 14) {
        this.$nextTick(() => {
          this.$refs.objectiveException.init();
        });
      }
      if (this.$route.query.errTypeId == 16) {
        this.$nextTick(() => {
          this.$refs.subjectiveException.init();
        });
      }
    },
    showTips() {
      this.$refs.exceptionComment.init();
    },
  },
};
</script>
<style lang="scss">
.handleException {
  .sub-btn-box {
    width: 100%;
    text-align: center;
  }
  .type-check-box {
    display: flex;

    div {
      cursor: pointer;
      margin-right: 14px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .succ-box {
    height: calc(100vh - 141px);
    width: calc(100% - 94px);
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
    p {
      width: 100%;
      color: #525252;
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
  .exceptionList {
    width: 440px;
    border-left: 1px solid #e7e7e7;
    height: calc(100vh - 78px);
    .err-list-box {
      padding-bottom: 120px;
      height: calc(100vh - 200px);
      .btn-box {
        padding: 18px 24px;
        position: fixed;
        right: 0;
        bottom: 0;
        background-color: #ffffff;
        width: 439px;
        border-top: 1px solid #e7e7e7;
        z-index: 10;
        .el-button {
          width: 100%;
        }
        .two-btn {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          .el-button {
            width: 48%;
          }
        }
      }
      .err-item-box {
        padding: 12px 24px;
        cursor: pointer;
        position: relative;
        // > div {
        //   position: relative;
        // }
        .err-item-cnt {
          display: flex;
          align-items: center;
        }
        .el-checkbox-group {
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);

          .el-checkbox__label {
            display: none;
          }
          .el-checkbox {
            margin-bottom: 0;
          }
        }
        .err-item-cnt-msg {
          padding-left: 12px;
        }
        .el-icon-caret-left {
          color: #ffffff;
          font-size: 16px;
        }
        &:hover {
          color: #333333;
          background: #f2f5f8;
          .el-icon-caret-left {
            color: #f2f5f8;
          }
        }
      }
      .err-item-box.err-item-on {
        color: #333333;
        background: #f2f5f8;
        .el-icon-caret-left {
          color: #333333;
        }
      }
    }
    .type-check {
      display: flex;
      justify-content: space-between;
      padding: 18px 24px;
      border-bottom: 1px solid #e7e7e7;
      .el-checkbox {
        margin-bottom: 0;
      }
      .btn-span {
        cursor: pointer;
        user-select: none;
      }
      .type-check-item {
        position: relative;
        cursor: pointer;
        &::after {
          position: absolute;
          width: 80%;
          height: 1px;
          content: "";
          left: 10%;
          bottom: -8px;
        }
        &:hover {
          color: #2474ed;
          &::after {
            background-color: #2474ed;
          }
        }
      }
      .btn-box {
        .border {
          color: #ececec;
        }
      }
      .type-check-on {
        color: #2474ed;
        &::after {
          background-color: #2474ed;
        }
      }
    }
    .exception-tilte {
      font-size: 16px;
      color: #0a1119;
      line-height: 1;
      padding: 18px 24px;
      font-weight: 500;
      border-bottom: 1px solid #e7e7e7;
    }
  }
  .handle-left {
    width: calc(100vw - 440px);

    .err-tips {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 12px 24px;
      border-bottom: 1px solid #e7e7e7;
      background: #f9f9f9;
    }
    .err-tips {
      color: #3e4551;
    }
  }
}
</style>
<style lang="scss" scoped>
.handleException {
  .handle-cnt {
    display: flex;
    justify-content: space-between;
  }
}
</style>
