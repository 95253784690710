<template>
  <div class="exceptionList">
    <div class="exception-tilte">{{ schoolInfo.name }}</div>
    <div class="type-check">
      <div class="type-check-box">
        <div :class="{ 'type-check-on': isHandle == 0 }" @click="checkType(0)">
          待处理：({{ getNums(0) }})
        </div>
        <div
          v-if="$route.query.errTypeId == 6 || $route.query.errTypeId == 7"
          :class="{ 'type-check-on': isHandle == 1 }"
          @click="checkType(1)"
        >
          已处理：({{ getNums(1) }})
        </div>
      </div>
      <div class="btn-box">
        <el-checkbox v-if="isBatch" v-model="isBatchAll" @change="checkAll()"
          >全选</el-checkbox
        >
        <span class="border"> | </span>
        <span class="btn-span" @click="isBatch = !isBatch">批量操作</span>
      </div>
    </div>
    <div class="err-list-box edit-scroll-style">
      <template v-if="$route.query.errTypeId == 20">
        <div
          v-for="(item, index) in errorList"
          :key="index"
          class="err-item-box"
          :class="{ 'err-item-on': item.studentId == indexError.studentId }"
          @click="checkItem(item)"
        >
          <div class="err-item-cnt">
            <i class="el-icon-caret-left"></i>
            <el-checkbox-group v-if="isBatch" v-model="checkAllList">
              <el-checkbox :label="item.studentId"></el-checkbox>
            </el-checkbox-group>
            学生：{{ item.studentName }} {{ item.studentHwId }}
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="(item, index) in errorList"
          :key="index"
          class="err-item-box"
          :class="{ 'err-item-on': item.id == indexError.id }"
          @click="checkItem(item)"
        >
          <div class="err-item-cnt">
            <i class="el-icon-caret-left"></i>
            <el-checkbox-group v-if="isBatch" v-model="checkAllList">
              <el-checkbox :label="item.id"></el-checkbox>
            </el-checkbox-group>
            <div class="err-item-cnt-msg">
              {{ item.deviceId }}-{{ item.batch }}-第{{ item.sequenceOrder }}张
              <span v-if="item.isTrim" style="padding-left: 23px; color: red"
                >已裁切卡</span
              >
              <div v-if="errTypeId == 7 || (errTypeId == 6 && isHandle == 1)">
                姓名：{{ item.studentName }}
              </div>
              <div v-if="errTypeId == 7 || errTypeId == 6">
                学号：{{ item.studentHwId }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="btn-box">
        <!-- 图像不完整-->
        <template v-if="$route.query.errTypeId == 2">
          <template v-if="isRecyclebin">
            <div class="two-btn">
              <el-button
                type="danger"
                plain
                icon="el-icon-delete "
                :loading="delBtn"
                @click="btnFn('delPictureDelete', 'delBtn')"
              >
                清空
              </el-button>
              <el-button @click="goRecyclebin()"> 关闭 </el-button>
            </div>
            <el-button
              type="primary"
              plain
              icon="el-icon-circle-check"
              :loading="setBtn"
              @click="btnFn('setReduction', 'setBtn')"
            >
              还原图像
            </el-button>
          </template>
          <template v-else>
            <div class="two-btn">
              <el-button
                type="primary"
                plain
                icon="el-icon-circle-check"
                :loading="setBtn"
                @click="btnFn('setisetnormal', 'setBtn')"
              >
                图像完整
              </el-button>
              <el-button
                type="danger"
                :loading="delBtn"
                plain
                icon="el-icon-delete"
                @click="btnFn('delintorecycle', 'delBtn')"
              >
                删除答卷
              </el-button>
            </div>
            <el-button plain icon="el-icon-delete" @click="goRecyclebin()">
              回收站
            </el-button>
          </template>
        </template>
        <!-- 定位异常 -->
        <template v-if="$route.query.errTypeId == 3">
          <div class="two-btn">
            <el-button
              type="danger"
              plain
              icon="el-icon-delete"
              :loading="delBtn"
              @click="btnFn('delPictureDelete', 'delBtn')"
            >
              删除答卷
            </el-button>
            <el-button
              type="primary"
              plain
              icon="el-icon-full-screen"
              :loading="setBtn"
              @click="btnFn('setidentifyAgain', 'setBtn')"
            >
              重新识别
            </el-button>
          </div>
        </template>
        <!-- 页码异常 -->
        <template v-if="$route.query.errTypeId == 20">
          <div class="two-btn">
            <el-button
              type="danger"
              plain
              icon="el-icon-delete"
              :loading="delBtn"
              @click="delItem()"
            >
              删除答卷
            </el-button>
            <el-button
              type="primary"
              plain
              icon="el-icon-full-screen"
              :loading="setBtn"
              @click="showModelTips()"
            >
              核准缺页
            </el-button>
          </div>
        </template>
        <!-- 二维码异常 -->
        <template v-if="$route.query.errTypeId == 19">
          <div class="two-btn">
            <el-button
              type="danger"
              plain
              icon="el-icon-delete"
              :loading="delBtn"
              @click="btnFn('delPictureDelete', 'delBtn')"
            >
              删除答卷
            </el-button>
            <el-button
              type="primary"
              plain
              icon="el-icon-full-screen"
              :loading="setBtn"
              @click="btnFn('setidentifyAgain', 'setBtn')"
            >
              重新识别
            </el-button>
          </div>
          <el-button
            type="primary"
            plain
            icon="el-icon-circle-check"
            @click="showChange()"
          >
            设为正常
          </el-button>
        </template>
        <!-- 学号填涂异常 -->
        <template v-if="$route.query.errTypeId == 6">
          <div class="two-btn">
            <el-button
              type="danger"
              plain
              icon="el-icon-delete"
              :loading="delBtn"
              @click="btnFn('delPictureDelete', 'delBtn')"
            >
              删除答卷
            </el-button>
            <el-button
              type="primary"
              plain
              icon="el-icon-full-screen"
              :loading="setBtn"
              @click="btnFn('setidentifyAgain', 'setBtn')"
            >
              重新识别
            </el-button>
            <el-button
              type="primary"
              plain
              icon="el-icon-back"
              :loading="backBtn"
              @click="btnFn('backLocation', 'backBtn')"
            >
              退卡
            </el-button>
          </div>
          <el-autocomplete
            v-model="student"
            :disabled="isHandle == 1"
            class="input-width"
            :loading="btnLoading"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入学生名/学号搜索学生"
            @select="handleSelect"
            @input="getexamstudentList"
            @blur="setOldKey"
          ></el-autocomplete>
        </template>
        <!-- 学号填涂异常 -->
        <template v-if="$route.query.errTypeId == 7">
          <div class="two-btn">
            <el-button
              type="danger"
              plain
              icon="el-icon-delete"
              :loading="delBtn"
              @click="btnFn('delPictureDelete', 'delBtn')"
            >
              删除答卷
            </el-button>
            <el-button
              type="primary"
              plain
              icon="el-icon-full-screen"
              :loading="setBtn"
              @click="btnFn('setidentifyAgain', 'setBtn')"
            >
              重新识别
            </el-button>
          </div>
          <el-autocomplete
            v-model="student"
            :disabled="isHandle == 1"
            class="input-width"
            :loading="btnLoading"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入学生名/学号搜索学生"
            @select="handleSelect"
            @input="getexamstudentList"
            @blur="setOldKey"
          ></el-autocomplete>
        </template>
      </div>
    </div>
    <toNormal ref="toNormal"></toNormal>
    <missingPage ref="missingPage"></missingPage>
  </div>
</template>
<script>
import { getStore } from "@/core/util/store";
import {
  misspageList,
  getErrorlist,
  identifyAgain,
  pictureDelete,
  setnormal,
  intorecycle,
  getrecyclebin,
  reduction,
  getstudentlist,
  scribbleStudent,
  repeatStudent,
  appointErrortypeNums,
  locationScan,
  backLocation,
} from "@/core/api/homework/scan";
import toNormal from "./toNormal.vue";
import missingPage from "./missingPage.vue";
import { mapGetters } from "vuex";

export default {
  name: "ExceptionList",
  components: { toNormal, missingPage },

  props: {
    sheetData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      student: "",
      btnLoading: false,
      schoolInfo: {},
      indexError: {},
      errorList: [],
      checkAllList: [],
      isHandle: 0,
      errTypeId: 0,
      isBatch: false,
      backBtn: false,
      isBatchAll: false,
      setBtn: false,
      delBtn: false,
      changeBtn: false,
      isRecyclebin: false,
      studentList: [],
      typeList: [],
    };
  },
  computed: {
    ...mapGetters(["GET_SCAN_ERR"]),
  },
  created() {
    this.getInfo();
    // 测试函数
  },
  methods: {
    //获取顶部异常数量
    getAappointErrortypeNums() {
      let data = {
        hwId: this.$route.query.hwId,
        errTypeId: this.$route.query.errTypeId,
        type: "",
      };
      appointErrortypeNums(data).then((res) => {
        this.typeList = res.data.data || [];
      });
    },
    // 记录下input最后输入的值
    setOldKey() {
      this.oldKeyName = this.student;
    },
    getexamstudentList() {
      if (!this.student) {
        this.studentList = [];
        return;
      }
      let data = {
        hwId: this.$route.query.hwId,
        keyword: this.student,
      };
      this.studentList = [];
      getstudentlist(data).then((res) => {
        if (this.student != res.config.params.keyword) return;
        this.studentList = res.data.data.map((item) => {
          return {
            value:
              item.name +
              (item.studentHwId ? ` [学号：${item.studentHwId}]` : "") +
              (item.classNum ? ` [班级：${item.classNum}]` : ""),
            id: item.id,
            studentHwId: item.studentHwId,
          };
        });
        // console.log(JSON.parse(JSON.stringify(this.studentList)));
      });
    },
    querySearchAsync(queryString, cb) {
      cb(this.studentList);
    },
    handleSelect(item) {
      // console.log(item);
      if (
        this.$route.query.errTypeId == 6 ||
        this.$route.query.errTypeId == 7
      ) {
        let data = {
          id: this.indexError.id,
          studentHwId: item.studentHwId,
          studentId: item.id,
        };
        // console.log(data);
        const h = this.$createElement;
        this.$msgbox({
          title: "温馨提示",
          message: h("p", null, [
            h("span", null, "确定将此试卷分配给 "),
            h("p", { style: "color: #f56c6c" }, item.value),
            h("span", null, " 是否确定?"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              if (this.$route.query.errTypeId == 6) {
                this.setscribbleStudent(data, instance, done);
              }
              if (this.$route.query.errTypeId == 7) {
                this.setrepeatStudent(data, instance, done);
              }
            } else {
              done();
            }
          },
        })
          .then(() => {})
          .catch(() => {
            this.student = this.oldKeyName;
          });
      }
    },
    setscribbleStudent(data, instance, done) {
      scribbleStudent(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "指定成功！",
            type: "success",
          });
          this.init();
          instance.confirmButtonLoading = false;
          done();
          this.student = "";
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },
    setrepeatStudent(data, instance, done) {
      repeatStudent(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "指定成功！",
            type: "success",
          });
          this.init();
          instance.confirmButtonLoading = false;
          done();
          this.student = "";
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },
    showChange() {
      let array = [];
      let { isBatch, checkAllList } = this;
      if (isBatch) {
        array = checkAllList;
      } else {
        array = this.indexError.id ? [this.indexError.id] : [];
      }
      if (array.length == 0) {
        this.$message({
          showClose: true,
          message: "请先选择需要处理的数据！",
          type: "warning",
        });
        return;
      }
      this.$refs.toNormal.init(array);
    },
    goRecyclebin() {
      this.isRecyclebin = !this.isRecyclebin;
      this.init();
    },
    getNums(key) {
      let arr = this.typeList.filter((item) => item.isHandle == key);
      let indexObj = arr[0] || {};
      return indexObj.number || 0;
    },
    showModelTips() {
      if (!this.indexError.studentId) {
        this.$message({
          showClose: true,
          message: "请先选择需要处理的数据！",
          type: "warning",
        });
        return;
      }
      this.$refs.missingPage.init();
    },
    delItem() {
      let array = [];
      if (this.isBatch) {
        array = this.checkAllList;
      } else {
        array = this.indexError.studentId ? [this.indexError.studentId] : [];
      }
      if (array.length == 0) {
        this.$message({
          showClose: true,
          message: "请先选择需要处理的数据！",
          type: "warning",
        });
        return;
      }
      let subArr = [];
      this.errorList.map((item) => {
        if (array.indexOf(item.studentId) != -1) {
          let ids = item.hwRecordVOList.map((item) => item.hwRecordId);
          subArr = subArr.concat(ids);
        }
      });
      this.delPictureDelete({ ids: subArr }, "delBtn");
    },
    btnFn(key, btnKey) {
      let data = {};
      if (this.isBatch) {
        data.ids = this.checkAllList;
      } else {
        data.ids = this.indexError.id ? [this.indexError.id] : [];
      }
      if (data.ids.length == 0) {
        this.$message({
          showClose: true,
          message: "请先选择需要处理的数据！",
          type: "warning",
        });
        return;
      }
      this[key](data, btnKey);
    },
    getMisspageList() {
      let data = { hwId: this.$route.query.hwId };
      misspageList(data)
        .then((res) => {
          this.errorList = res.data.data || [];
          this.indexError = this.errorList[0] || {};
          // console.log(this.indexError);
          if (this.indexError && this.indexError.studentId) {
            this.$parent.$refs.pictureProcessing.initImg(this.indexError);
          } else {
            this.$parent.$refs.pictureProcessing.initImg({});
          }
        })
        .catch(() => {
          this.errorList = [];
        });
    },
    setlocationScan(data, btnKey) {
      locationScan(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "重新识别成功！",
            type: "success",
          });
          this[btnKey] = false;
          this.init();
        })
        .catch(() => {
          this[btnKey] = false;
        });
    },
    backLocation(data, btnKey) {
      backLocation(data.ids)
        .then(() => {
          this.$message({
            showClose: true,
            message: "退卡成功！",
            type: "success",
          });
          this[btnKey] = false;
          this.init();
        })
        .catch(() => {
          this[btnKey] = false;
        });
    },
    setidentifyAgain(data, btnKey) {
      this[btnKey] = true;

      if (this.$route.query.errTypeId == 3) {
        let locationArr = this.$parent.$refs.pictureProcessing.locationArr;
        let arr = [];
        locationArr.map((item) => {
          let pushArr = item.markLocation.map((index) => {
            return index.source;
          });
          arr.push(pushArr);
          return item;
        });

        data.locationArr = JSON.stringify(arr.flat());
        data.pageNumber = this.indexError.pageNumber;
        this.setlocationScan(data, btnKey);
        return;
      }
      identifyAgain(data.ids)
        .then(() => {
          this.$message({
            showClose: true,
            message: "重新识别成功！",
            type: "success",
          });
          this[btnKey] = false;
          this.init();
        })
        .catch(() => {
          this[btnKey] = false;
        });
    },
    setisetnormal(data, btnKey) {
      this[btnKey] = true;
      setnormal(data.ids)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功！",
            type: "success",
          });
          this[btnKey] = false;
          this.init();
        })
        .catch(() => {
          this[btnKey] = false;
        });
    },
    delPictureDelete(data, btnKey) {
      const h = this.$createElement;
      let str = "删除答卷,是否继续? ";
      if (this.$route.query.errTypeId == 2) {
        str = "清空回收站,是否继续? ";
      }
      this.$msgbox({
        title: "温馨提示",
        message: h("p", null, [h("span", null, str)]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            this[btnKey] = true;
            pictureDelete(data.ids)
              .then(() => {
                this.$message({
                  showClose: true,
                  message:
                    this.$route.query.errTypeId == 2
                      ? "清空成功!"
                      : "删除成功！",
                  type: "success",
                });
                this[btnKey] = false;
                this.init();
                done();
                instance.confirmButtonLoading = false;
              })
              .catch(() => {
                this[btnKey] = false;
                instance.confirmButtonLoading = false;
              });
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    setReduction(data, btnKey) {
      this[btnKey] = true;
      // console.log(data.ids);
      reduction(data.ids)
        .then(() => {
          this.$message({
            showClose: true,
            message: "还原成功！",
            type: "success",
          });
          this[btnKey] = false;
          this.init();
        })
        .catch(() => {
          this[btnKey] = false;
        });
    },
    delintorecycle(data, btnKey) {
      const h = this.$createElement;
      this.$msgbox({
        title: "温馨提示",
        message: h("p", null, [h("span", null, "删除答卷,是否继续? ")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            this[btnKey] = true;
            intorecycle(data.ids)
              .then(() => {
                this.$message({
                  showClose: true,
                  message: "删除成功,已放回回收站！",
                  type: "success",
                });
                this[btnKey] = false;
                this.init();
                done();
                instance.confirmButtonLoading = false;
              })
              .catch(() => {
                this[btnKey] = false;
                instance.confirmButtonLoading = false;
              });
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    // 回收站列表
    getrecyclebinList() {
      let data = {
        hwId: this.$route.query.hwId,
      };
      getrecyclebin(data).then((res) => {
        this.errorList = res.data.data || [];
        if (this.indexError.id) {
          let arr = this.errorList.filter(
            (item) => item.id == this.indexError.id
          );
          if (arr && arr.length > 0) {
            this.indexError = arr[0];
          } else {
            this.indexError = this.errorList[0] || {};
          }
        } else {
          this.indexError = this.errorList[0] || {};
        }
        this.$parent.$refs.pictureProcessing.initImg(this.indexError);
      });
    },
    // 获取指定异常列表全部数据（缺页异常、主、客观题异常不适用此接口）
    getErrorlist() {
      let data = {
        hwId: this.$route.query.hwId,
        errTypeId: this.$route.query.errTypeId,
        isHandle: this.isHandle || 0,
      };
      getErrorlist(data).then((res) => {
        if (data.errTypeId != this.$route.query.errTypeId) {
          return;
        }
        if (data.errTypeId == 2) {
          this.errorList = res.data.data.hwRecordVOS || [];
        } else {
          this.errorList = res.data.data || [];
        }
        if (this.indexError.id) {
          let arr = this.errorList.filter(
            (item) => item.id == this.indexError.id
          );
          if (arr && arr.length > 0) {
            this.indexError = arr[0];
          } else {
            this.indexError = this.errorList[0] || {};
          }
        } else {
          this.indexError = this.errorList[0] || {};
        }
        this.$parent.$refs.pictureProcessing.initImg(this.indexError);
      });
    },
    checkType(val) {
      this.isHandle = val;
      this.init();
    },
    init() {
      // this.sheetData = this.$parent.sheetData;
      this.errTypeId = this.$route.query.errTypeId;
      this.isBatchAll = false;
      this.checkAllList = [];
      this.errorList = [];
      this.getAappointErrortypeNums();
      // 回收站列表
      if (this.errTypeId == 2 && this.isRecyclebin) {
        this.getrecyclebinList();
        setTimeout(() => {
          this.$parent.$refs.headerBox.init();
        }, 200);
        return;
      }
      if (this.errTypeId == 20) {
        this.getMisspageList();
      }
      if (this.errTypeId != 20) {
        this.getErrorlist();
      }
      setTimeout(() => {
        this.$parent.$refs.headerBox.init();
      }, 200);
    },
    checkItem(item) {
      this.indexError = item;
      this.$parent.$refs.pictureProcessing.initImg(this.indexError);
    },
    checkAll() {
      if (this.$route.query.errTypeId == 20) {
        if (this.isBatchAll) {
          this.checkAllList = this.errorList.map((item) => item.studentId);
        } else {
          this.checkAllList = [];
        }
      } else {
        if (this.isBatchAll) {
          this.checkAllList = this.errorList.map((item) => item.id);
        } else {
          this.checkAllList = [];
        }
      }
    },
    getInfo() {
      this.schoolInfo = getStore({ name: "schoolInfo" });
    },
  },
};
</script>
<style lang="scss" scoped>
.input-width {
  width: 100%;
}
</style>
