<template>
  <div class="pictureProcessing">
    <div
      v-if="indexImg"
      ref="imgCntBox"
      class="img-cnt-box edit-scroll-style"
      @mousewheel="zoomRegion($event)"
    >
      <div ref="imgCnt" class="img-box">
        <img :src="indexImg" alt="" />
        <template
          v-if="
            newLocation &&
            $route.query.errTypeId == 3 &&
            indexLocation.markLocation
          "
        >
          <div
            v-for="(item, index) in indexLocation.markLocation"
            :key="index"
            class="loacition-arr"
            :style="getStyle(item.source)"
            @mousemove.prevent="mousemoveTest($event, index)"
            @mousedown.prevent="mousedownTest($event, item)"
          ></div
        ></template>
      </div>
    </div>
    <div v-else class="succ-box">
      <div>
        <img src="@/static/scanSheet/default-diagram.png" alt="" />
        <p>异常处理完成，老师您辛苦了!</p>
      </div>
    </div>
    <div class="btn-box">
      <template v-if="$route.query.errTypeId == 20">
        <template v-if="indexErr && indexErr.hwRecordVOList">
          <template v-for="(v, i) in showImgList">
            <div
              :key="i"
              class="check-item-box"
              :class="{ on: scanUrlCurrent == v - 1, 'dis-btn': getDis(v) }"
              @click="checkItemImg(v, i)"
            >
              第{{ v }}页
              <!-- <div class="tips">缺</div> -->
            </div>
            <div v-if="showLine(v)" :key="i + 'line'" class="line-box"></div>
          </template>
        </template>
        <!-- <div
          v-for="(item, index) in indexErr.hwRecordVOList"
          :key="index"
          class="btn-box-item"
        >
          <div
            v-for="(v, i) in item.urls"
            :key="i"
            class="check-item-box"
            :class="{ on: v == indexImg }"
            @click="checkImg(v, i)"
          >
            第{{ i + 1 + ((item.pageNumber || 1) - 1) * 2 }}面
          </div>
        </div> -->
      </template>
      <template v-else>
        <div
          v-for="(v, i) in imgArr"
          :key="i"
          class="check-item-box"
          :class="{ on: scanUrlCurrent == i }"
          @click="checkImg(v, i)"
        >
          第{{ i + 1 }}面
        </div>
      </template>
      <div class="btn-item">
        <template
          v-if="
            $route.query.errTypeId == 3 ||
            $route.query.errTypeId == 5 ||
            $route.query.errTypeId == 15 ||
            $route.query.errTypeId == 19 ||
            $route.query.errTypeId == 6
          "
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="交换试卷正反面"
            placement="left"
          >
            <el-button
              type="text"
              icon="el-icon-sort"
              :disabled="btnDis"
              @click="exchange()"
            >
              交换
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="反向旋转90°"
            placement="left"
          >
            <el-button
              type="text"
              icon="el-icon-refresh-left"
              :disabled="btnDis"
              @click="rotateImg(0)"
            >
              旋转
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="正向旋转90°"
            placement="left"
          >
            <el-button
              type="text"
              icon="el-icon-refresh-right"
              :disabled="btnDis"
              @click="rotateImg(1)"
            >
              旋转
            </el-button>
          </el-tooltip>
        </template>
        <el-button type="text" icon="el-icon-zoom-in" @click="zoom(1)">
          放大
        </el-button>
        <el-button type="text" icon="el-icon-zoom-out" @click="zoom(0)">
          缩小
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { hwrecordUpdateurls } from "@/core/api/homework/scan";
// import getters from "@/core/services/store/getters";
export default {
  name: "PictureProcessing",
  props: {
    sheetData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgArr: [
        "https://static.wtjy.com/sheet/202304/b83a8844-ed9a-4f6c-bd3f-7c0a5f1540c8.jpg",
        "https://static.wtjy.com/sheet/202304/b1401fcc-e674-4440-8bf3-99f4740d1fad.jpg",
      ],
      locationArr: [],
      showImgList: [],
      indexLocation: {},
      indexImg: "",
      scanUrlCurrent: 0,
      btnDis: false,
      newLocation: true,
      indexErr: {},
    };
  },
  created() {
    // this.initView();
  },
  mounted() {
    // document.body.addeventlistener
    document.body.addEventListener("mouseup", this.mouseupAll);
  },
  destroyed() {
    // 移除鼠标移动事件
    // document.removeEventListener("mousemove", this.mousemoveAll);
    // 移除鼠标放开事件
    document.body.removeEventListener("mouseup", this.mouseupAll);
    // window.removeEventListener("mousedown", this.handleonmousedown);
  },
  methods: {
    // 鼠标抬起
    mouseupAll() {
      this.mouseupTest();
    },
    // 当鼠标指针在指定的元素中移动时
    mousemoveTest($event, i) {
      if (!this.dragType) {
        return;
      }
      this.indexBox = i;
      let { clientWidth, clientHeight } = this.$refs.imgCnt;
      this.clientWidth = clientWidth;
      this.clientHeight = clientHeight;
      const proportion = this.oldWidth / this.clientWidth;
      const { endMouseTop, endMouseLeft } = this.mouseDragState;

      // 计算鼠标移动的距离
      const distance = Math.abs(
        parseInt(((endMouseTop - $event.clientY) * 100).toString(), 10) / 100
      );
      const distanceLeft = Math.abs(
        parseInt(((endMouseLeft - $event.clientX) * 100).toString(), 10) / 100
      );
      // let ques;
      if (endMouseLeft <= $event.clientX) {
        this.indexQuestion.source.left =
          this.indexQuestion.source.left + distanceLeft * proportion;
      } else {
        this.indexQuestion.source.left =
          this.indexQuestion.source.left - distanceLeft * proportion;
      }
      //  最小值限制 start
      if (endMouseTop <= $event.clientY) {
        this.indexQuestion.source.top =
          this.indexQuestion.source.top + distance * proportion;
      } else {
        this.indexQuestion.source.top =
          this.indexQuestion.source.top - distance * proportion;
      }
      if (this.indexQuestion.source.top <= 1) {
        this.indexQuestion.source.top = 1;
      }
      if (this.indexQuestion.source.left <= 1) {
        this.indexQuestion.source.left = 1;
      }
      // 最小值限制 end
      // 最大值限制 start
      // 到右侧最大距离
      if (
        this.indexQuestion.source.left + this.indexQuestion.source.width >=
        this.clientWidth * proportion
      ) {
        this.indexQuestion.source.left =
          this.clientWidth * proportion - this.indexQuestion.source.width - 1;
      }
      // 到下方最大距离
      if (
        this.indexQuestion.source.top + this.indexQuestion.source.height >=
        this.clientHeight * proportion
      ) {
        this.indexQuestion.source.top =
          this.clientHeight * proportion - this.indexQuestion.source.height - 1;
      }
      let el = $event.target;
      el.left = this.indexQuestion.source.left + "px";
      el.top = this.indexQuestion.source.top + "px";
      el.style.zIndex = 10;
      // 更新鼠标最后移动的位置（Y轴）
      this.mouseDragState.endMouseTop = $event.clientY;
      this.mouseDragState.endMouseLeft = $event.clientX;
    },
    // 按下触发 event 鼠标事件v 当前点击的盒子  type 判断是那种拖动 indexF追加区域情况，当前父元素
    mousedownTest($event, v) {
      // 阻止默认行为
      $event.preventDefault();
      let str = "";
      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      let practice_item = document.getElementsByClassName("hidden-div");
      let practice_item_length = practice_item.length;
      for (let i = 0; i < practice_item_length; i++) {
        practice_item[i].style.pointerEvents = "none";
        practice_item[i].style.userSelect = "none";
      }

      this.mouseDragState = {
        // 鼠标开始移动的位置（Y轴）
        startMouseTop: $event.clientY,
        // 鼠标最后到的位置（Y轴）
        endMouseTop: $event.clientY,
        // 鼠标开始移动的位置(x轴)
        startMouseLeft: $event.clientX,
        // 鼠标最后到的位置（X轴）
        endMouseLeft: $event.clientX,
      };
      this.dragType = true;
      this.indexQuestion = Object.assign({}, v);
    },
    // 抬起触发
    mouseupTest() {
      // console.log(this.indexQuestion);
      if (!this.indexQuestion || !this.indexQuestion.id) {
        this.indexQuestion = {};
        return;
      }
      let indexLocation = JSON.parse(JSON.stringify(this.indexLocation));
      indexLocation.markLocation[this.indexBox] = JSON.parse(
        JSON.stringify(this.indexQuestion)
      );
      this.dragType = false;
      this.indexQuestion = {};
      this.indexLocation = indexLocation;
      this.locationArr[this.scanUrlCurrent] = JSON.parse(
        JSON.stringify(this.indexLocation)
      );
    },
    // 拖动 end
    rotateImg(type) {
      this.rotateCurrent = this.scanUrlCurrent;
      let array = [];
      let { isBatch, checkAllList, errorList } =
        this.$parent.$refs.exceptionList;
      if (isBatch) {
        array = errorList.filter((item) => checkAllList.indexOf(item.id) != -1);
      } else {
        array = this.indexErr.id ? [this.indexErr] : [];
      }
      if (array.length == 0) {
        this.$message({
          showClose: true,
          message: "请先选择需要处理的数据！",
          type: "warning",
        });
        return;
      }
      array = JSON.parse(JSON.stringify(array));
      array.map((index, i) => {
        array[i] = this.rotate(type, index);
      });
      this.sethwrecordUpdateurls(array);
    },
    // 旋转图片
    rotate(type, listData) {
      // 页面显示的markurl
      // 需要将显示的和不显示的都修改

      let url = listData.urls.split("，")[this.scanUrlCurrent];
      let rotateDeg = 0;
      let sharpenStr = "";
      let rotateList = url.split("image/rotate,");
      let sharpen = url.split("image/sharpen,");

      if (rotateList[1]) {
        rotateDeg = Number(rotateList[1].split(",")[0]);
      }
      if (sharpen[1]) {
        sharpenStr = sharpen[1].split(",")[0];
      }
      let urls = listData.urls.split("，");

      if (type) {
        rotateDeg = Number(rotateDeg) + 90;
      } else {
        rotateDeg = Number(rotateDeg) - 90;
      }

      if (rotateDeg < 0) {
        rotateDeg = 360 + rotateDeg;
      }
      rotateDeg = rotateDeg % 360;
      if (rotateDeg == 0 || rotateDeg == 360) {
        url = "";
      } else {
        url = "?x-oss-process=image/rotate," + rotateDeg;
      }

      // 做了次修改 旋转之后  图片改为普通的urls
      if (this.scanUrlCurrent != 0) {
        urls[this.scanUrlCurrent] =
          urls[this.scanUrlCurrent].split("，")[0].split("?")[0] + url;
        if (sharpenStr) {
          urls[this.scanUrlCurrent] =
            urls[this.scanUrlCurrent] + ",image/sharpen," + sharpenStr;
        }
      } else {
        urls.map((item, i) => {
          urls[i] = item.split("，")[0].split("?")[0] + url;
          if (sharpenStr) {
            urls[i] = urls[i] + ",image/sharpen," + sharpenStr;
          }
        });
      }
      let data = {
        id: listData.id,
        markUrls: urls.join("，"),
        urls: urls.join("，"),
      };
      return data;
      //
    },
    // 交换图片
    exchange() {
      let array = [];
      let { isBatch, checkAllList, errorList } =
        this.$parent.$refs.exceptionList;
      if (isBatch) {
        array = errorList.filter((item) => checkAllList.indexOf(item.id) != -1);
      } else {
        array = this.indexErr.id ? [this.indexErr] : [];
      }
      if (array.length == 0) {
        this.$message({
          showClose: true,
          message: "请先选择需要处理的数据！",
          type: "warning",
        });
        return;
      }
      array = JSON.parse(JSON.stringify(array));

      array.map((index) => {
        index.markUrls = index.markUrls.split("，").reverse();
        index.urls = index.urls.split("，").reverse();

        index.markUrls = index.markUrls.join("，");
        index.urls = index.urls.join("，");
      });

      this.sethwrecordUpdateurls(array);
    },
    sethwrecordUpdateurls(data) {
      this.btnDis = true;
      hwrecordUpdateurls(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "处理成功!",
            type: "success",
          });
          this.$parent.$refs.exceptionList.init();
          this.btnDis = false;
        })
        .catch(() => {
          this.btnDis = false;
        });
    },
    showLine(item) {
      if (item % this.sheetData.pageCount == 0) {
        return true;
      } else {
        return false;
      }
    },
    checkItemImg(v, i) {
      if (this.getDis(v)) {
        this.$message({
          showClose: true,
          message: "当前页缺失！",
          type: "error",
        });
        return;
      }
      this.scanUrlCurrent = i;
      this.indexErr.hwRecordVOList.map((item) => {
        item.urls.map((bitem, bindex) => {
          if (
            (item.pageNumber - 1) * this.sheetData.pageCount + bindex ==
            v - 1
          ) {
            this.indexImg = bitem;
          }
        });
      });
    },
    getDis(v) {
      let type = true;
      if (!this.indexErr.hwRecordVOList) return false;
      this.indexErr.hwRecordVOList.map((item) => {
        item.urls.map((bitem, bindex) => {
          if (
            (item.pageNumber - 1) * this.sheetData.pageCount + bindex ==
            v - 1
          ) {
            type = false;
          }
        });
      });
      return type;
    },
    checkImg(v, i) {
      this.indexImg = v;

      this.scanUrlCurrent = i;
      if (this.$route.query.errTypeId == 3) {
        // 切换后  重新获取定位点
        this.indexLocation = JSON.parse(
          JSON.stringify(this.locationArr[this.scanUrlCurrent])
        );
      }
    },
    zoom(val) {
      if (!this.$refs.imgCnt) return;
      let { clientWidth, clientHeight } = this.$refs.imgCnt;
      if (val) {
        if (clientHeight < 3000) {
          clientHeight = clientHeight / 0.9;
          clientWidth = clientWidth / 0.9;
        }
      } else {
        if (clientHeight > 200) {
          clientHeight = clientHeight * 0.9;
          clientWidth = clientWidth * 0.9;
        }
      }
      this.clientWidth = clientWidth;
      this.clientHeight = clientHeight;
      this.newLocation = false;
      this.$nextTick(() => {
        this.$refs.imgCnt.style.height = clientHeight + "px";
        this.$refs.imgCnt.style.width = clientWidth + "px";
        this.newLocation = true;
      });
      this.proportion = clientHeight / this.oldHeight;
    },
    // 缩放区域
    zoomRegion($event) {
      if ($event.ctrlKey) {
        $event.preventDefault();
        let { clientWidth, clientHeight } = this.$refs.imgCnt;
        if ($event.wheelDelta > 0) {
          if (clientHeight < 3000) {
            clientHeight = clientHeight / 0.9;
            clientWidth = clientWidth / 0.9;
          }
        } else {
          if (clientHeight > 200) {
            clientHeight = clientHeight * 0.9;
            clientWidth = clientWidth * 0.9;
          }
        }
        this.clientWidth = clientWidth;
        this.clientHeight = clientHeight;
        this.newLocation = false;
        this.$nextTick(() => {
          this.$refs.imgCnt.style.height = clientHeight + "px";
          this.$refs.imgCnt.style.width = clientWidth + "px";
          this.newLocation = true;
        });
        this.proportion = clientHeight / this.oldHeight;
      }
    },
    async callFunction(img) {
      return new Promise((resolve) => {
        img.onload = () => {
          this.oldWidth = img.width;
          this.oldHeight = img.height;
          resolve(true);
        };
      });
    },
    async setImg() {
      // 创建img对象
      let img = document.createElement("img");
      // console.log(this.$refs.scanImgBox);
      // 设置src值
      img.src = encodeURI(this.indexImg);
      //设置canvas属性 oldWidth oldHeight
      await this.callFunction(img);
    },
    sortByField(array, field) {
      return array.sort((a, b) => a[field] - b[field]);
    },
    theMissingPage() {
      // 再做一次处理 先计算出整个需要显示的页码
      const array = this.sheetData.hwSheetMarkVOS.map((item) => item.pageIndex);
      this.showImgList = array;
      // console.log(array);
      if (this.indexErr.hwRecordVOList) {
        this.indexErr.hwRecordVOList = this.indexErr.hwRecordVOList.map(
          (item) => {
            item.urls = item.urls.split("，");
            return item;
          }
        );
        this.indexErr.hwRecordVOList = this.sortByField(
          this.indexErr.hwRecordVOList,
          "pageNumber"
        );
        // console.log(JSON.parse(JSON.stringify(this.indexErr)));

        this.indexImg = this.indexErr.hwRecordVOList[0].urls[0];
        this.indexErr = JSON.parse(JSON.stringify(this.indexErr));
        this.scanUrlCurrent =
          (this.indexErr.hwRecordVOList[0].pageNumber - 1) *
          this.sheetData.pageCount;
      } else {
        this.indexImg = "";
        this.scanUrlCurrent = 0;
        this.indexErr = {};
      }
      this.$nextTick(() => {
        this.initView();
      });
    },
    initImg(data) {
      if (this.$route.query.errTypeId == 20) {
        this.indexImg = "";
        this.scanUrlCurrent = 0;
        this.indexErr = {};
        this.indexErr = JSON.parse(JSON.stringify(data));
        this.theMissingPage();
        return;
      }
      if (!data || !data.id) {
        this.indexImg = "";
        this.imgArr = [];
        return;
      }
      this.indexErr = JSON.parse(JSON.stringify(data));
      this.imgArr = data.markUrls
        ? data.markUrls.split("，")
        : data.urls.split("，");
      if (this.$route.query.errTypeId == 3) {
        this.imgArr = data.urls.split("，");
      }
      if (this.rotateCurrent) {
        this.indexImg = this.imgArr[this.rotateCurrent];
        this.scanUrlCurrent = this.rotateCurrent;
        this.rotateCurrent = 0;
      } else {
        this.scanUrlCurrent = 0;
        this.indexImg = this.imgArr[0];
      }

      this.$nextTick(() => {
        this.initView();
      });
    },
    // js实现点击复制
    initLoaction() {
      //获取当前页面的位置
      let locationArr = this.imgArr.map((item, index) => {
        return (
          this.indexErr.pageNumber * this.sheetData.pageCount -
          (this.imgArr.length - index - 1)
        );
      });
      // console.log(locationArr);
      locationArr = locationArr.map((item) => {
        let index = this.sheetData.hwSheetMarkVOS.filter(
          (bitem) => bitem.pageIndex == item
        );
        index = JSON.parse(JSON.stringify(index[0] || {}));
        index.markLocation = JSON.parse(index.markLocation);
        return index;
      });
      this.locationArr = locationArr;

      this.indexLocation = JSON.parse(
        JSON.stringify(locationArr[this.scanUrlCurrent])
      );
    },
    getStyle(item) {
      let width = `width:${item.width * this.proportion}px;`;
      let top = `top:${item.top * this.proportion}px;`;
      let height = `height:${item.height * this.proportion}px;`;
      let lineHeight = `line-height:${item.height * this.proportion}px;`;
      let left = `left:${item.left * this.proportion}px;`;
      return width + top + height + lineHeight + left;
    },
    async initView() {
      if (!this.$refs.imgCntBox) return;
      let { clientHeight } = this.$refs.imgCntBox;
      clientHeight = clientHeight + 400;

      await this.setImg();
      this.$refs.imgCnt.style.height = clientHeight + "px";
      let proportion = clientHeight / this.oldHeight;
      this.clientWidth = proportion * this.oldWidth;
      this.$refs.imgCnt.style.width = this.clientWidth + "px";
      this.clientHeight = clientHeight;
      this.proportion = proportion;
      // 如果是定位异常  就去获取下定位点信息
      if (this.$route.query.errTypeId == 3) {
        this.initLoaction();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pictureProcessing {
  display: flex;

  .btn-box {
    position: relative;
    padding: 24px 8px;
    width: 77px;
    .btn-box-item {
      border-bottom: 1px solid #dddddd;
      &:last-child {
        border-bottom: none;
      }
    }

    .line-box {
      border-bottom: 1px solid #dddddd;
      margin-bottom: 10px;
    }
    .check-item-box {
      // margin: 0 auto;
      width: 64px;
      text-align: center;
      line-height: 28px;
      border-radius: 2px;
      margin-bottom: 10px;
      color: #aaaaaa;
      cursor: pointer;
      border: 1px solid #aaaaaa;
      position: relative;
      user-select: none;
      .tips {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        font-size: 12px;
        display: inline-block;
        line-height: 1;
        padding: 1px;
        background-color: #f56c6b;
        color: #ffffff;
        border-radius: 4px 0px 4px 0px;
      }
    }
    .dis-btn {
      background: #fff2f2;
      color: #f56c6b;
      border: 1px solid #f56c6b;
      position: relative;
      .tips {
        opacity: 1;
      }
    }
    .on {
      color: #ffffff;
      background-color: $primary-color;
      border: 1px solid $primary-color;
    }
  }
  .btn-item {
    position: absolute;
    bottom: 20px;
    text-align: center;
    background-color: #ffffff;
    .el-button {
      margin-left: 0;
      margin-top: 26px;
    }
  }
  .img-box {
    // width: 100%;
    position: relative;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .loacition-arr {
    position: absolute;
    border: 1px solid red;
  }
  .img-cnt-box {
    width: calc(100vw - 520px);
    height: calc(100vh - 125px);
    background-color: #f3f3f3;
    padding: 24px;
    flex-shrink: 0;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dee1e7;
    }
  }
}
</style>
