<template>
  <div class="header-box">
    <div class="title">
      {{ $parent.sheetData.name }}/{{ $parent.sheetData.subjectName }}
    </div>
    <div class="error-check">
      <div
        v-for="(item, index) in errorList"
        :key="index"
        class="error-check-item"
      >
        <div
          class="error-check-box"
          :class="{ on: item.type == $route.query.errTypeId }"
          @click="checkIndex(item)"
        >
          {{ item.name
          }}<span v-if="item.number" class="number">{{ item.number }}</span>
        </div>
        <i v-if="index != errorList.length - 1" class="el-icon-more"></i>
      </div>
      <template v-if="$route.query.password">
        <el-button
          type="text"
          :loading="btnloading"
          style="color: #f56c6c; margin-left: 40px"
          @click="setReset()"
          >重新识别</el-button
        >
      </template>
    </div>
  </div>
</template>
<script>
const errorList = [
  { name: "二维码异常", type: 19, number: 0 },
  { name: "图像不完整", type: 2, number: 0 },
  { name: "定位异常", type: 3, number: 0 },
  { name: "学号填涂异常", type: 6, number: 0 },
  { name: "学号重复", type: 7, number: 0 },
  { name: "客观题异常", type: 14, number: 0 },
  { name: "主观题批改异常", type: 16, number: 0 },
  { name: "缺页异常", type: 20, number: 0 },
];
import { errortypeNums, reset } from "@/core/api/homework/scan";
import { getStore } from "@/core/util/store";
export default {
  name: "HeaderBox",
  data() {
    return {
      errorList: errorList,
      btnloading: false,
    };
  },
  created() {},
  methods: {
    init() {
      this.getErrortypeNums();
    },
    getErrortypeNums() {
      let data = {
        hwId: this.$route.query.hwId,
      };
      errortypeNums(data).then((res) => {
        let data = res.data.data;
        this.errorList = this.errorList.map((item) => {
          item.number = 0;
          return item;
        });
        if (!data) return;
        this.errorList = this.errorList.map((item) => {
          data.map((index) => {
            if (item.type == index.errorTypeId) {
              item.number = index.number;
              item.isHandle = index.isHandle;
            }
          });
          return item;
        });
        let array = this.errorList.filter(
          (item) => item.type == this.$route.query.errTypeId
        );
        // console.log(array);
        this.$store.commit("SET_SCAN_ERR", array[0]);
      });
    },
    setReset() {
      let schoolId = getStore({ name: "school_id" });
      let data = {
        hwId: this.$route.query.hwId,
        schoolId: schoolId,
      };
      this.btnloading = true;
      reset(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "识别成功,即将刷新页面!",
            type: "success",
          });

          setTimeout(() => {
            this.btnloading = false;
            window.location.reload();
          }, 1000);
        })
        .catch(() => {
          this.btnloading = false;
        });
    },
    checkIndex(item) {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.errTypeId = item.type;
      this.$store.commit("SET_SCAN_ERR", item);
      this.$router.push({
        query: query,
      });
      this.$parent.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.header-box {
  .error-check {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 13px 24px;
    line-height: 1;
    border-bottom: 1px solid #e7e7e7;
    .error-check-item {
      display: flex;
      align-items: center;
      .error-check-box {
        position: relative;
        cursor: pointer;
      }
      .on {
        color: #2474ed;
      }

      .number {
        background: #f56c6b;
        border-radius: 50%;
        position: absolute;
        right: -14px;
        top: -11px;
        color: #ffffff;
        font-size: 12px;
        padding: 1px 4px;
        letter-spacing: 0;
      }
    }
    .el-icon-more {
      padding: 0 24px;
    }
  }
  .title {
    height: 34px;
    background: #2474ed;
    padding: 0 24px;
    line-height: 34px;
    color: #ffffff;
  }
}
</style>
