var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pictureProcessing"},[(_vm.indexImg)?_c('div',{ref:"imgCntBox",staticClass:"img-cnt-box edit-scroll-style",on:{"mousewheel":function($event){return _vm.zoomRegion($event)}}},[_c('div',{ref:"imgCnt",staticClass:"img-box"},[_c('img',{attrs:{"src":_vm.indexImg,"alt":""}}),(
          _vm.newLocation &&
          _vm.$route.query.errTypeId == 3 &&
          _vm.indexLocation.markLocation
        )?_vm._l((_vm.indexLocation.markLocation),function(item,index){return _c('div',{key:index,staticClass:"loacition-arr",style:(_vm.getStyle(item.source)),on:{"mousemove":function($event){$event.preventDefault();return _vm.mousemoveTest($event, index)},"mousedown":function($event){$event.preventDefault();return _vm.mousedownTest($event, item)}}})}):_vm._e()],2)]):_c('div',{staticClass:"succ-box"},[_vm._m(0)]),_c('div',{staticClass:"btn-box"},[(_vm.$route.query.errTypeId == 20)?[(_vm.indexErr && _vm.indexErr.hwRecordVOList)?[_vm._l((_vm.showImgList),function(v,i){return [_c('div',{key:i,staticClass:"check-item-box",class:{ on: _vm.scanUrlCurrent == v - 1, 'dis-btn': _vm.getDis(v) },on:{"click":function($event){return _vm.checkItemImg(v, i)}}},[_vm._v(" 第"+_vm._s(v)+"页 ")]),(_vm.showLine(v))?_c('div',{key:i + 'line',staticClass:"line-box"}):_vm._e()]})]:_vm._e()]:_vm._l((_vm.imgArr),function(v,i){return _c('div',{key:i,staticClass:"check-item-box",class:{ on: _vm.scanUrlCurrent == i },on:{"click":function($event){return _vm.checkImg(v, i)}}},[_vm._v(" 第"+_vm._s(i + 1)+"面 ")])}),_c('div',{staticClass:"btn-item"},[(
          _vm.$route.query.errTypeId == 3 ||
          _vm.$route.query.errTypeId == 5 ||
          _vm.$route.query.errTypeId == 15 ||
          _vm.$route.query.errTypeId == 19 ||
          _vm.$route.query.errTypeId == 6
        )?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"交换试卷正反面","placement":"left"}},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-sort","disabled":_vm.btnDis},on:{"click":function($event){return _vm.exchange()}}},[_vm._v(" 交换 ")])],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"反向旋转90°","placement":"left"}},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-refresh-left","disabled":_vm.btnDis},on:{"click":function($event){return _vm.rotateImg(0)}}},[_vm._v(" 旋转 ")])],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"正向旋转90°","placement":"left"}},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-refresh-right","disabled":_vm.btnDis},on:{"click":function($event){return _vm.rotateImg(1)}}},[_vm._v(" 旋转 ")])],1)]:_vm._e(),_c('el-button',{attrs:{"type":"text","icon":"el-icon-zoom-in"},on:{"click":function($event){return _vm.zoom(1)}}},[_vm._v(" 放大 ")]),_c('el-button',{attrs:{"type":"text","icon":"el-icon-zoom-out"},on:{"click":function($event){return _vm.zoom(0)}}},[_vm._v(" 缩小 ")])],2)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/static/scanSheet/default-diagram.png"),"alt":""}}),_c('p',[_vm._v("异常处理完成，老师您辛苦了!")])])
}]

export { render, staticRenderFns }