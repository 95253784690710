<template>
  <div class="missingPage">
    <el-dialog
      title="核准缺页"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="468px"
    >
      <div>
        确定答卷缺少的页码
        <div class="page-items-box">
          <div
            v-for="(iItem, iIndex) in nums"
            :key="iIndex"
            :class="{ 'on-img': showtips(iItem) }"
            class="page-items"
          >
            {{ iItem }}
            <div class="tips">缺</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitList()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { approval } from "@/core/api/homework/scan";

export default {
  name: "MissingPage",
  data() {
    return {
      dialogVisible: false,
      nums: 0,
      btnLoading: false,
    };
  },
  created() {},
  methods: {
    submitList() {
      let data = {
        hwId: this.$route.query.hwId,
        studentId: this.indexError.studentId,
      };
      this.btnLoading = true;
      approval(data)
        .then(() => {
          this.dialogVisible = false;
          this.btnLoading = false;
          this.$message({
            showClose: true,
            message: "核准成功！",
            type: "success",
          });
          this.$parent.init();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    showtips(val) {
      let type = true;
      this.indexError.hwRecordVOList.map((item) => {
        let nums = 0;
        item.urls.map((oItem, oIndex) => {
          nums = oIndex + 1 + (item.pageNumber - 1) * 2;
          if (nums == val) {
            type = false;
          }
        });
      });
      return type;
    },
    init() {
      this.dialogVisible = true;
      this.indexError = JSON.parse(JSON.stringify(this.$parent.indexError));
      this.sheetData = this.$parent.sheetData;
      this.nums = this.sheetData.hwSheetMarkVOS.length;
      this.indexError.hwRecordVOList = this.indexError.hwRecordVOList.map(
        (item) => {
          item.urls = item.urls.split("，");
          return item;
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.missingPage {
  .page-items-box {
    display: flex;
  }
  .page-items {
    width: 50px;
    height: 50px;
    background: #f9fafc;
    border-radius: 4px;
    border: 1px solid #dee0e7;
    line-height: 50px;
    text-align: center;
    margin-right: 18px;
    margin-top: 18px;
    position: relative;
    .tips {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      font-size: 12px;
      display: inline-block;
      line-height: 1;
      padding: 1px;
      background-color: #f56c6b;
      color: #ffffff;
      border-radius: 4px 0px 4px 0px;
    }
  }
  .on-img {
    background: #fff2f2;
    border-radius: 4px;
    border: 1px solid #f56c6b;
    .tips {
      opacity: 1;
    }
  }
}
</style>
