<template>
  <div class="exceptionComment">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div v-if="$route.query.errTypeId == 2">
        1、若答卷有效信息（定位区、学号区、作答区等）均完整，请点击“图像完整”，图像将自动进入下一个识别环节。
        <br />
        2、若答卷有效信息不完整，请参考批次信息找到纸质答卷，重新扫描后，针对该图像请点击“删除答卷”。
        <br />
        3、若误删除，可从回收站还原。<br />
        4、若大量异常均属同一类问题，请选择“批量操作”。
      </div>
      <div v-if="$route.query.errTypeId == 3">
        <!-- 1、拖动定位点（或定位线及标题框）红框，使其与模板定位信息匹配后，请点击“重新识别”。<br /> -->
        1、若重新识别或者重扫均无法解决时，说明模板与答卷的定位信息、标题信息不匹配，请点击“标记异常卷”（模板为多张时不支持），确认考生信息后，考试管理员需在“异常卷”页面批改分数。<br />
        2、若答卷是扫错或多余的，请点击“删除答卷”。<br />
        3、若大量异常均属同一类问题，请选择“批量操作”。
      </div>
      <div v-if="$route.query.errTypeId == 5 || $route.query.errTypeId == 15">
        1、若答卷属于该学科考试，请点击“设为正常”。 <br />
        2、若答卷不属于该学科考试，请点击“删除答卷”。 <br />
        3、若大量异常均属同一类问题，请选择“批量操作”。
      </div>
      <div v-if="$route.query.errTypeId == 6">
        1、若学号识别有误，请手动修改并搜索确认。 <br />
        2、若学号识别出来但不在计划考生列表内，请更新考生信息后再搜索确认。
        <br />
        3、若大量异常均需删除，请选择“批量操作”。
      </div>
      <div v-if="$route.query.errTypeId == 7">
        1、若学生学号信息错误，请先处理错误学生的答卷；若另一个学号是正确的，直接搜索确认。
        <br />
        2、若重复扫描，请删除其中一个，另一个直接搜索确认。
      </div>
      <div v-if="$route.query.errTypeId == 13">
        1、若答卷有作答痕迹，误涂缺考标记的，请点击“设为非缺考”；若答卷无作答痕迹，确实为缺考的，无需修改；每页修改完成后，请点击“提交本页”。
        <br />
        2、已处理列表中，确认非缺考的网阅卡，主观题需要老师阅卷。 <br />
        3、已处理页面不支持修改。
      </div>
      <div v-if="$route.query.errTypeId == 11">
        1、若答卷AB卷填涂痕迹痕迹，误涂A
        B卷的，请点击“A卷或者B卷”；每页修改完成后，请点击“提交本页”。
        <br />
        2、已处理列表中，仍然可以修改AB卷，修改后请点击“提交本页”。
      </div>
      <div v-if="$route.query.errTypeId == 16">
        1、请根据主观题实际作答结果修改，每页修改完成后，请点击“提交本页”。
        <br />
        2、若发现处理错了，已处理中支持二次修改。
      </div>
      <div v-if="$route.query.errTypeId == 14">
        1、请根据客观题实际：书写结果 修改，每页修改完成后，请点击“提交本页”。
        <br />
        2、若发现处理错了，已处理中支持二次修改。
      </div>
      <div v-if="$route.query.errTypeId == 19">
        1、若答卷属于该本次作业，请点击“设为正常”。 <br />
        2、若答卷不属于本次作业，请点击“删除答卷”。 <br />
        3、若大量异常均属同一类问题，请选择“批量操作”。
      </div>
      <div v-if="$route.query.errTypeId == 20">
        1、可删除答卷，然后找齐其他页面重新扫描。<br />
        2、可核准缺页，该份作业将以已有的答卷页进行统计。<br />
        3、若大量异常均属同一类问题，请选择“批量操作”。
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "ExceptionComment",
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {},
  methods: {
    init() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.exceptionComment {
}
</style>
