<template>
  <div v-if="showImg" class="show-img-box stop-img">
    <div class="show-img-cnt-box">
      <div v-loading="imgLoading" class="img-canvas">
        <img
          ref="imgBox"
          class="back"
          :src="imgTime(imgsrcList[indexSrc])"
          alt=""
          :style="getRoate()"
          @load="imgOnError"
          @mousedown="move"
          @mousewheel.prevent="rollImg"
        />
      </div>

      <div class="viewer__close view_btn" @click="getOld()">
        <i class="el-icon-close"></i>
      </div>
      <div
        v-if="srcList.length > 1"
        class="viewer__prev view_btn"
        @click="prev"
      >
        <i class="el-icon-arrow-left"></i>
      </div>
      <div
        v-if="srcList.length > 1"
        class="viewer__next view_btn"
        @click="next"
      >
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="el-image-viewer__btn el-image-viewer__actions">
        <div class="el-image-viewer__actions__inner">
          <i class="el-icon-zoom-out" @click="zoom(0)"></i>
          <i class="el-icon-zoom-in" @click="zoom(1)"></i>
          <!-- <i class="el-icon-full-screen"></i> -->
          <i class="el-icon-refresh-left" @click="refresh(0)"></i>
          <i class="el-icon-refresh-right" @click="refresh(1)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imgTime } from "@/core/util/util";
export default {
  name: "AddView",
  components: {},
  props: {
    // showImg: {
    //   type: Boolean,
    //   default: false,
    // },
    srcList: {
      type: Array,
      default() {},
    },
  },
  data() {
    return {
      indexSrc: 0,
      scale: 1,
      rotate: 0,
      top: 0,
      left: 0,
      showImg: false,
      imgLoading: true,
      imgsrcList: [],
    };
  },
  watch: {
    srcList: {
      deep: true,
      handler() {
        this.setImg();
      },
    },
  },
  created() {
    this.indexSrc = 0;
    this.scale = 1;
    this.rotate = 0;
  },
  mounted() {
    //
  },
  methods: {
    imgTime: imgTime,
    imgOnError() {
      this.imgLoading = false;
    },
    callFunction(img) {
      return new Promise((resolve) => {
        img.onload = () => {
          this.oldWidth = img.width;
          this.oldHeight = img.height;
          resolve(true);
        };
      });
    },
    async setImg() {
      this.indexSrc = 0;
      this.scale = 1;
      this.rotate = 0;
      // 创建img对象
      let img = document.createElement("img");
      // console.log(this.$refs.markSheet);
      // 设置src值
      img.src = encodeURI(this.srcList[0]);
      await this.callFunction(img);
      let clientWidth = window.screen.width;
      let clientHeight = window.screen.height;
      if (this.oldWidth < this.oldHeight) {
        this.$refs.imgBox.style.height = clientHeight - 200 + "px";
        this.$refs.imgBox.style.marginTop = "50px";
        let imgWidth = ((clientHeight - 200) * this.oldWidth) / this.oldHeight;
        // console.log(imgWidth);
        this.$refs.imgBox.style.marginLeft =
          (clientWidth - imgWidth) / 2 + "px";
      } else {
        this.$refs.imgBox.style.width = clientWidth - 400 + "px";
        this.$refs.imgBox.style.marginLeft = "200px";
      }
      this.imgsrcList = JSON.parse(JSON.stringify(this.srcList));
    },
    // 鼠标拖拽事件
    rollImg(e) {
      this.zoom(e.deltaY > 0 ? 0 : 1);
    },
    // 拖动图片
    move(e) {
      e.preventDefault();
      // 获取元素
      let left = document.querySelector(".img-canvas");
      let img = document.querySelector(".back");
      // let _this = this;
      let x = e.pageX - img.offsetLeft;
      let y = e.pageY - img.offsetTop;
      // console.log(x);
      // console.log(y);
      // 添加鼠标移动事件
      left.addEventListener("mousemove", move);
      function move(e) {
        // console.log(e.pageY);
        // console.log(y);
        img.style.marginLeft = e.pageX - x + "px";
        img.style.marginTop = e.pageY - y + "px";
      }
      // 添加鼠标抬起事件，鼠标抬起，将事件移除
      img.addEventListener("mouseup", function () {
        left.removeEventListener("mousemove", move);
      });
      // 鼠标离开父级元素，把事件移除
      left.addEventListener("mouseout", function () {
        left.removeEventListener("mousemove", move);
      });
    },
    getRoate() {
      return `transform: scale(${this.scale}) rotate(${this.rotate}deg)`;
    },
    zoom(type) {
      if (type) {
        this.scale = this.scale + 0.2;
      } else {
        if (this.scale > 0.4) {
          this.scale = this.scale - 0.2;
        }
      }
    },
    refresh(type) {
      if (type) {
        this.rotate = this.rotate + 90;
      } else {
        this.rotate = this.rotate - 90;
      }
    },
    prev() {
      if (this.indexSrc > 0) {
        this.indexSrc--;
        this.imgLoading = true;
      }
    },
    next() {
      if (this.indexSrc < this.srcList.length - 1) {
        this.indexSrc++;
        this.imgLoading = true;
      }
      // this.setImg();
    },
    getOld() {
      this.showImg = false;
      this.imgsrcList = [];
    },
  },
};
</script>
<style scoped lang="scss">
.show-img-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200000;
  img {
    transition: transform 0.3s ease 0s;
    max-height: 100%;
    max-width: 100%;
  }
  .show-img-cnt-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    .el-image-viewer__btn {
      i {
        cursor: pointer;
      }
    }
    .view_btn {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: 0.8;
      cursor: pointer;
      box-sizing: border-box;
      user-select: none;
    }
    .viewer__prev {
      left: 40px;
      text-indent: 2px;
      top: 50%;
      transform: translateY(-50%);
      width: 44px;
      height: 44px;
      font-size: 24px;
      color: #fff;
      background-color: #606266;
      border-color: #fff;
      z-index: 9999999999;
    }
    .viewer__next {
      right: 40px;
      text-indent: 2px;
      top: 50%;
      transform: translateY(-50%);
      width: 44px;
      height: 44px;
      font-size: 24px;
      color: #fff;
      background-color: #606266;
      border-color: #fff;
      z-index: 9999999999;
    }
    .viewer__close {
      top: 40px;
      right: 40px;
      width: 40px;
      height: 40px;
      font-size: 24px;
      color: #fff;
      background-color: #606266;
      z-index: 9999999999;
    }
  }
}
</style>
