<template>
  <div class="toNormal">
    <el-dialog
      title="设为正常"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="430px"
    >
      <div>请确定答卷属于本次作业，并指定当前答卷为第几张。</div>
      <div style="margin: 14px 0">
        请选择答卷是第几张：
        <el-select
          v-model="pageNumber"
          style="width: 140px"
          placeholder="请选择"
        >
          <el-option
            v-for="item in numberItem"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { qrSetnormal } from "@/core/api/homework/scan";
export default {
  name: "ToNormal",
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      pageNumber: "",
      numberItem: 0,
    };
  },
  created() {},
  methods: {
    submit() {
      if (!this.pageNumber) {
        this.$message({
          showClose: true,
          message: "请选择需要指定的页数",
          type: "warning",
        });
        return;
      }
      this.setqrSetnormal();
    },
    init(arr) {
      if (!this.$parent.indexError.id) {
        this.$message({
          showClose: true,
          message: "请选择需要处理的数据！",
          type: "warning",
        });
        return;
      }
      this.pageNumber = "";
      this.indexError = this.$parent.indexError;
      this.numberItem = this.$parent.sheetData.numberCount || 6;
      this.dialogVisible = true;
      this.indexArr = arr;
    },
    setqrSetnormal() {
      this.btnLoading = true;
      let data = {
        pageNumber: this.pageNumber,
        ids: this.indexArr.join(","),
      };
      qrSetnormal(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功！",
            type: "success",
          });
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$parent.init();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.toNormal {
}
</style>
